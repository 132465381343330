import { useMemo, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CodeSnippet = ({ id, data }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [copied, setCopied] = useState(false);

  const memoizedData = useMemo(() => {
    if (!data) {
      return null;
    }
    setIsLoading(false);
    return data.filter((code) => code.id === id);
  }, [data, id]);

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (!memoizedData) {
    return <div>Code not found</div>;
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(memoizedData[0]?.Code_snippets || '').then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="grid place-items-center min-h-screen" style={{ backgroundColor: "#2d323e" }}>
      <div className="flex flex-col max-w-3xl w-full text-white rounded-lg overflow-hidden">
        {/* Header Section */}
        <div className="flex items-center justify-end px-2 py-2 border-b">
  <span
    className={`flex items-center text-white rounded px-1 py-1 text-sm hover:bg-blue-700 transition-all ${copied ? "" : "hidden"}`}
    onClick={copyToClipboard}
    aria-label="Copy to clipboard"
  >
    <ContentCopyIcon fontSize="small" />
    <span className="ml-2">{copied ? "Copied!" : ""}</span>
  </span>
</div>

        {/* Updated Code Block */}
        {memoizedData.map((code, idx) => (
          <SyntaxHighlighter
            key={idx}
            wrapLongLines={true}
            language="python"
            style={atomOneDark}
            customStyle={{
              padding: "16px",
              backgroundColor: "#2d323e",
              borderRadius: "0 0 8px 8px",
            }}
          >
            {code.Code_snippets}
          </SyntaxHighlighter>
        ))}
      </div>
    </div>
  );
};

export default CodeSnippet;
