import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import './css_general_content/link_component.css'
import { Link } from 'react-router-dom';

const Links=({data,id})=>
{
    const param=useParams();
    const [specificLink,setSpecificLink]= useState(null);
   
    useEffect(()=>{
        setSpecificLink(data.filter(link=>link.id===id));
    },[data,id])
    return(
        <>
        <div className="linkColor">
        { specificLink &&  
              
            specificLink.map((link,idx)=>
                
                link.Name==="create API key here."?
                <Link to={`${link.Url.substring(2)}#generateapikey`}>{link.Name}</Link>
                : 
                <a key={idx} href={link.Url.substring(2)}> {link.Name}</a>
            
           )
        }
        </div>
        </>
    )
}
export default Links;