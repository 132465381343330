import { MDBFooter } from "mdb-react-ui-kit";
import { useEffect } from "react";
import "./footer.css";
const Footer = ({ data, Routes_list }) => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <MDBFooter
      className="textcol text-center text-lg-start text-muted bg-body-tertiary fixed-bottom"
      expand="lg"
      fixed="top"
    >
      <div
        className="text-center p-4"
        style={{ backgroundColor: "#303846", color: "white" }}
      >
        © {year} Copyright :
        <a className="text-reset fw-bold" href="https://arifpay.net/">
          Arifpay, Financial Technologies S.C
        </a>
      </div>
    </MDBFooter>
  );
};
export default Footer;
