// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first {
    background-color: #d4edda; 
    border-radius: 15px; /* rounded corners */
    padding: 10px; /* space around content */
    margin: 5px,5px,5px,0; /* space around the div */
    font-family: Arial, sans-serif; /* font */
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .second {
    margin: 5px 0; /* space above and below */
  }
  
  .third {
    margin: 5px 0; /* space above and below */
  }
  
  .forth {
    color: #155724; /* dark green */
    font-weight: bold; /* make checkmark bold */
  }
  
  .note {
    font-size: 10px;
    margin: 2px 0; /* space above and below each note */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/General_Content_components/css_general_content/note_content.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB,EAAE,oBAAoB;IACzC,aAAa,EAAE,yBAAyB;IACxC,qBAAqB,EAAE,yBAAyB;IAChD,8BAA8B,EAAE,SAAS;IACzC,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,aAAa,EAAE,0BAA0B;EAC3C;;EAEA;IACE,aAAa,EAAE,0BAA0B;EAC3C;;EAEA;IACE,cAAc,EAAE,eAAe;IAC/B,iBAAiB,EAAE,wBAAwB;EAC7C;;EAEA;IACE,eAAe;IACf,aAAa,EAAE,oCAAoC;EACrD","sourcesContent":[".first {\n    background-color: #d4edda; \n    border-radius: 15px; /* rounded corners */\n    padding: 10px; /* space around content */\n    margin: 5px,5px,5px,0; /* space around the div */\n    font-family: Arial, sans-serif; /* font */\n    margin-bottom: 20px;\n    margin-top: 20px;\n  }\n  \n  .second {\n    margin: 5px 0; /* space above and below */\n  }\n  \n  .third {\n    margin: 5px 0; /* space above and below */\n  }\n  \n  .forth {\n    color: #155724; /* dark green */\n    font-weight: bold; /* make checkmark bold */\n  }\n  \n  .note {\n    font-size: 10px;\n    margin: 2px 0; /* space above and below each note */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
