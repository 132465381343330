import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
const CustomComponent = ({ Tag, children, id, ...props }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const scrollToElement = () => {
        const element = document.querySelector(location.hash);
        if (element) {
          const yOffset = -70; // Adjust this value to match your header's height
          const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
        } else {
          // Retry scrolling after a short delay if the element is not yet available
          setTimeout(scrollToElement, 100);
        }
      };

      scrollToElement();
    }
  }, [location]);
    return <Tag
        id={children==="Generate API key"?"generateapikey":children}
        {...props}>
        {/* {children==="Generate API key"?"generateapikey":children} */}
        {children}
    </Tag>;
};
  
  // Usage
  // <CustomComponent Tag="h1" style={{ color: 'red' }}>Hello World</CustomComponent>
  export default CustomComponent;