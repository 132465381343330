// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content_images{
    max-width: 600px; /* Maximum width */
    width: 100%; /* Responsive width */
    height: auto; /* Maintain aspect ratio */
    margin-top: 20px;
    margin-right: 100px;
    border-radius: 20px;
    margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/General_Content_components/css_general_content/image_content.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB,EAAE,kBAAkB;IACpC,WAAW,EAAE,qBAAqB;IAClC,YAAY,EAAE,0BAA0B;IACxC,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".content_images{\n    max-width: 600px; /* Maximum width */\n    width: 100%; /* Responsive width */\n    height: auto; /* Maintain aspect ratio */\n    margin-top: 20px;\n    margin-right: 100px;\n    border-radius: 20px;\n    margin-bottom: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
