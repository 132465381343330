import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
} from "mdb-react-ui-kit";
import "./nav-bar.css";
import "../Nav-bar/nav-bar.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const NavBar = ({ url, data, sdk, Routes_list, onchange }) => {
  const navigate = useNavigate();
  const [activeNav, setActiveNav] = useState("");
  const navigate1 = useNavigate();
  const navigate2 = useNavigate();
  const handel_onclick = (name) => {
    navigate(Routes_list.filter((route) => route.Name === name)[0].Url);
  };
  const handel_onclick_nav = (name, e) => {
    setActiveNav(name);
    e.stopPropagation();
     
    navigate1(Routes_list.filter((route) => route.Name === name)[0].Url);
  };
  const hande_onclick_dropdown = (name, e) => {
    e.stopPropagation();
    navigate2(Routes_list.filter((route) => route.Name === name)[0].Url);
  };

  const [fields, setfields] = useState(null);
  const [openNavNoTogglerSecond, setOpenNavNoTogglerSecond] = useState(false);

  useEffect(() => {
    let _tempfields = [];
    for (let key in data[0]) {
      if (key.toLowerCase().includes("field")) {
        _tempfields.push(data[0][key]);
      }
    }
    setfields(_tempfields);
  }, [data]);
  return (
    <>
      {fields && (
        <MDBNavbar
          className="navbar fixed-top"
          expand="lg"
          light
          bgColor="light"
        >
          <MDBContainer fluid>
            {/* logo */}
            <MDBNavbarBrand href="#">
              <img
                alt="Logo_arifpay"
                src={url}
                height="30"
                width="100"
                style={{ margin: "0" }}
                onClick={() => handel_onclick("Logo")}
              ></img>
            </MDBNavbarBrand>
          
            <MDBNavbarToggler
              type="button"
              data-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setOpenNavNoTogglerSecond(!openNavNoTogglerSecond)}
              style={{ backgroundColor: "grey", height: "10" }}
            >
              <MDBIcon icon="burger" fas />
            </MDBNavbarToggler>

            <MDBCollapse navbar open={openNavNoTogglerSecond}>
              <MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
                {fields.map((field, idx) => (
                  <MDBNavbarItem key={idx}>
                    <MDBNavbarLink
                     active={activeNav === field}
                       
                      aria-current="page"
                      onClick={(event) => handel_onclick_nav(field, event)}
                      // className="navItems"
                      className={`navItems ${activeNav === field ? 'active' : ''}`} 
                    >
                      {field}
                    </MDBNavbarLink>
                  </MDBNavbarItem>
                ))}
                {/* How to */}
                <MDBNavbarItem 
                active={activeNav === "How To"}
                onClick={(event) =>
                  setActiveNav("How To")
                }
               key={3}>
                  <MDBNavbarLink
                     
                    aria-current="page"
                    onClick={(event) => {setActiveNav("How To"); navigate1("/howto")}}
                    className={`navItems ${activeNav === "How To" ? 'active' : ''}`} 
                  >
                    How To
                  </MDBNavbarLink>
                </MDBNavbarItem>
                {/* SDK and plugin */}
                <MDBNavbarItem
                  
                  active={activeNav === "SDK & Plugin"}
                onClick={(event) =>
                  setActiveNav("SDK & Plugin")
                }
                >
                  <MDBDropdown>
                    <MDBDropdownToggle
                      tag="a"
                      className="navItems nav-link"
                      role="button"
                    >
                      SDK & Plugin
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      {sdk.map((lang, idx) => (
                        <MDBDropdownItem
                          style={{ padding: "5px", cursor: "pointer" }}
                          key={idx}
                          link=""
                          onClick={(event) =>
                            hande_onclick_dropdown(lang.Name, event)
                          }
                        >
                          {lang.Name}
                        </MDBDropdownItem>
                      ))}
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavbarItem>
                {/* Developer Portal */}
                <MDBNavbarItem 
                  // active={activeNav === "Developer Community"}
                  onClick={(event) =>
                    setActiveNav("Developer Community")
                  }
                >
                  
                   <MDBDropdown>
                    <MDBDropdownToggle
                     
                      tag="a"
                      className="nav-link"
                      role="button"
                    >
                      Developer Community
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem
                        href="https://discordapp.com/users/1222634472109903955"
                        link
                      >
                        Discord
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        href="https://twitter.com/ArifPayDev"
                        link
                      >
                        Twitter
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        href="https://stackoverflow.com/users/23781145/arifpay"
                        link
                      >
                        Stack Overflow
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        href="https://t.me/+BALrDU8gqVtkZjY0"
                        link
                      >
                        Telegram
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        href="https://www.youtube.com/@arif-pay"
                        link
                      >
                        YouTube
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown> 
                </MDBNavbarItem>
              </MDBNavbarNav>
              {/* github */}
              <MDBNavbarNav
                className="ms-auto"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <MDBNavbarItem>
                  {/* <MDBNavbarLink href="https://github.com/arifpay-net"> */}
                  <MDBNavbarLink href="https://github.com/ArifPayDev">
                    Github
                  </MDBNavbarLink>
                </MDBNavbarItem>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
      )}
    </>
  );
};
export default NavBar;
