// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body
{
    margin: 0;
    padding: 0;
}
input[type="checkbox"]:checked ~ #root {
    background-color: rgb(64, 63, 63);
    color: white;
  }
  `, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;IAEI,SAAS;IACT,UAAU;AACd;AACA;IACI,iCAAiC;IACjC,YAAY;EACd","sourcesContent":["body\n{\n    margin: 0;\n    padding: 0;\n}\ninput[type=\"checkbox\"]:checked ~ #root {\n    background-color: rgb(64, 63, 63);\n    color: white;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
