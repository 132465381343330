// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copy {
    background-color: #70ff7041; /* Light grey background */
    border: none; /* No border */
    color: #000; /* Black text color */
    text-align: left;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5;
}
`, "",{"version":3,"sources":["webpack://./src/components/General_Content_components/css_general_content/copy.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B,EAAE,0BAA0B;IACvD,YAAY,EAAE,cAAc;IAC5B,WAAW,EAAE,qBAAqB;IAClC,gBAAgB;IAChB,qBAAqB;IACrB,qBAAqB;IACrB,eAAe;IACf,eAAe;IACf,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".copy {\n    background-color: #70ff7041; /* Light grey background */\n    border: none; /* No border */\n    color: #000; /* Black text color */\n    text-align: left;\n    text-decoration: none;\n    display: inline-block;\n    font-size: 16px;\n    margin: 4px 2px;\n    cursor: pointer;\n    border-radius: 5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
