// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Homepage_content_container {
  margin-top: 3em;
  width: 100%;
}
.Homepage_content1 {
  text-align: center;
  color: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3ca32b;
  height: 50%;
  margin-top: 10px;
  margin-bottom: 4.6em;
}

.Homepage_content2 {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
}
@media screen and (max-width: 1017px) {
  .Homepage_content2 {
    display: block;
  }
  .Homepage_content_container {
    height: 100%;
  }
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%;
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Set width to 100% for responsive video */
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Homepage/homepage.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,WAAW;EACX,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,WAAW;EACX,gBAAgB;AAClB;AACA;EACE;IACE,cAAc;EAChB;EACA;IACE,YAAY;EACd;AACF;AACA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW,EAAE,2CAA2C;EACxD,YAAY;AACd","sourcesContent":[".Homepage_content_container {\n  margin-top: 3em;\n  width: 100%;\n}\n.Homepage_content1 {\n  text-align: center;\n  color: #ffff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #3ca32b;\n  height: 50%;\n  margin-top: 10px;\n  margin-bottom: 4.6em;\n}\n\n.Homepage_content2 {\n  /* display: flex; */\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 50%;\n  overflow: hidden;\n}\n@media screen and (max-width: 1017px) {\n  .Homepage_content2 {\n    display: block;\n  }\n  .Homepage_content_container {\n    height: 100%;\n  }\n}\n.player-wrapper {\n  position: relative;\n  padding-top: 56.25%;\n}\n.react-player {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%; /* Set width to 100% for responsive video */\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
