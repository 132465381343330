const navigationData = [
  {
    "page": 1,
    "next": "https://developer.arifpay.net/#/checkoutoverview",
    "nextname": "Checkout Overview"
  },
  {
    "page": 5,
    "previous": "https://developer.arifpay.net/#/overview",
    "next": "https://developer.arifpay.net/#/createcheckoutsession",
    "nextname": "Create Checkout Session",
    "prevname": "Overview"
  },
  {
    "page": 6,
    "previous": "https://developer.arifpay.net/#/checkoutoverview",
    "next": "https://developer.arifpay.net/#/cancelcheckoutsession",
    "nextname": "Cancel Checkout Session",
    "prevname": "Checkout Overview"
  },
  {
    "page": 7,
    "previous": "https://developer.arifpay.net/#/createcheckoutsession",
    "next": "https://developer.arifpay.net/#/redirectuserstocheckout",
    "nextname": "Redirect Users to Checkout",
    "prevname": "Create Checkout Session"
  },
  {
    "page": 8,
    "previous": "https://developer.arifpay.net/#/cancelcheckoutsession",
    "next": "https://developer.arifpay.net/#/transactionconfirmation",
    "nextname": "Transaction Confirmation",
    "prevname": "Redirect Users to Checkout"
  },
  {
    "page": 9,
    "previous": "https://developer.arifpay.net/#/redirectuserstocheckout",
    "next": "https://developer.arifpay.net/#/createtestcheckoutsession",
    "nextname": "Create Test Checkout Session",
    "prevname": "Redirect Users to Checkout"
  },
  {
    "page": 10,
    "previous": "https://developer.arifpay.net/#/transactionconfirmation",
    "next": "https://developer.arifpay.net/#/paymentoverview",
    "nextname": "Payment Overview",
    "prevname": "Transaction Confirmation"
  },
  {
    "page": 55,
    "previous": "https://developer.arifpay.net/#/createtestcheckoutsession",
    "next": "https://developer.arifpay.net/#/supportedbanks",
    "nextname": "Supported Banks",
    "prevname": "Create Test Checkout Session"
  },
  {
    "page": 56,
    "previous": "https://developer.arifpay.net/#/paymentoverview",
    "next": "https://developer.arifpay.net/#/localwallet",
    "nextname": "Local Wallet",
    "prevname": "Payment Overview"
  },
  {
    "page": 57,
    "previous": "https://developer.arifpay.net/#/supportedbanks",
    "next": "https://developer.arifpay.net/#/express",
    "nextname": "International Wallet",
    "prevname": "Supported Banks"
  },
  {
    "page": 81,
    "previous": "https://developer.arifpay.net/#/localwallet",
    "next": "https://developer.arifpay.net/#/errorcodes",
    "nextname": "Error Codes",
    "prevname": "Local Wallet"
  },
  {
    "page": 21,
    "previous": "https://developer.arifpay.net/#/python",
    "next": "https://developer.arifpay.net/#/python",
    "nextname": "Arifpay Python Library",
    "prevname": "Error Codes"
  },
  {
    "page": 4,
    "previous": "https://developer.arifpay.net/#/errorcodes",
    "next": "https://developer.arifpay.net/#/pythoncheckoutsession",
    "nextname": "Python Checkout Session",
    "prevname": "Error Codes"
  },
  {
    "page": 12,
    "previous": "https://developer.arifpay.net/#/python",
    "next": "https://developer.arifpay.net/#/pythonchangelog",
    "nextname": "Python Change log",
    "prevname": "Arifpay Python Library"
  },
  {
    "page": 13,
    "previous": "https://developer.arifpay.net/#/pythoncheckoutsession",
    "next": "https://developer.arifpay.net/#/laravel",
    "nextname": "Arifpay Laravel Package",
    "prevname": "Python Checkout Session"
  },
  {
    "page": 22,
    "previous": "https://developer.arifpay.net/#/pythonchangelog",
    "next": "https://developer.arifpay.net/#/laravelcheckoutsession",
    "nextname": "Laravel Checkout Session",
    "prevname": "Python Change log"
  },
  {
    "page": 30,
    "previous": "https://developer.arifpay.net/#/laravel",
    "next": "https://developer.arifpay.net/#/laravelchangelog",
    "nextname": "Laravel Change log",
    "prevname": "Laravel Checkout Session"
  },
  {
    "page": 31,
    "previous": "https://developer.arifpay.net/#/laravelcheckoutsession",
    "next": "https://developer.arifpay.net/#/php",
    "nextname": "Arifpay PHP Package",
    "prevname": "Laravel Checkout Session"
  },
  {
    "page": 23,
    "previous": "https://developer.arifpay.net/#/laravelchangelog",
    "next": "https://developer.arifpay.net/#/phpcheckoutsession",
    "nextname": "PHP Checkout Session",
    "prevname": "Laravel Change log"
  },
  {
    "page": 36,
    "previous": "https://developer.arifpay.net/#/php",
    "next": "https://developer.arifpay.net/#/phpchangelog",
    "nextname": "PHP Change log",
    "prevname": "PHP Checkout Session"
  },
  {
    "page": 37,
    "previous": "https://developer.arifpay.net/#/phpcheckoutsession",
    "next": "https://developer.arifpay.net/#/go",
    "nextname": "Arifpay Go Package",
    "prevname": "PHP Checkout Session"
  },
  {
    "page": 25,
    "previous": "https://developer.arifpay.net/#/phpchangelog",
    "next": "https://developer.arifpay.net/#/gocheckoutsession",
    "nextname": "Go Checkout Session",
    "prevname": "PHP Change log"
  },
  {
    "page": 42,
    "previous": "https://developer.arifpay.net/#/go",
    "next": "https://developer.arifpay.net/#/gochangelog",
    "nextname": "Go Change log",
    "prevname": "Go Checkout Session"
  },
  {
    "page": 43,
    "previous": "https://developer.arifpay.net/#/gocheckoutsession",
    "next": "https://developer.arifpay.net/#/flutter",
    "nextname": "Arifpay Flutter Package",
    "prevname": "Go Checkout Session"
  },
  {
    "page": 26,
    "previous": "https://developer.arifpay.net/#/gochangelog",
    "next": "https://developer.arifpay.net/#/fluttercheckoutsession",
    "nextname": "Flutter Checkout Session",
    "prevname": "Go Change log"
  },
  {
    "page": 33,
    "previous": "https://developer.arifpay.net/#/flutter",
    "next": "https://developer.arifpay.net/#/flutterchangelog",
    "nextname": "Flutter Change log",
    "prevname": "Flutter Checkout Session"
  },
  {
    "page": 34,
    "previous": "https://developer.arifpay.net/#/fluttercheckoutsession",
    "next": "https://developer.arifpay.net/#/django",
    "nextname": "Arifpay Django Package",
    "prevname": "Flutter Checkout Session"
  },
  {
    "page": 49,
    "previous": "https://developer.arifpay.net/#/flutterchangelog",
    "next": "https://developer.arifpay.net/#/djangocreatesession",
    "nextname": "Django Create Session",
    "prevname": "Flutter Change log"
  },
  {
    "page": 65,
    "previous": "https://developer.arifpay.net/#/django",
    "next": "https://developer.arifpay.net/#/djangochangelog",
    "nextname": "Django Change log",
    "prevname": "Django Create Session"
  },
  {
    "page": 66,
    "previous": "https://developer.arifpay.net/#/djangocreatesession",
    "next": "https://developer.arifpay.net/#/express",
    "nextname": "Arifpay Express Library",
    "prevname": "Django Create Session"
  },
  {
    "page": 58,
    "previous": "https://developer.arifpay.net/#/djangochangelog",
    "next": "https://developer.arifpay.net/#/expresscheckoutsession",
    "nextname": "Express Checkout Session",
    "prevname": "Arifpay Django Change log"
  },
  {
    "page": 60,
    "previous": "https://developer.arifpay.net/#/express",
    "next": "https://developer.arifpay.net/#/expresschangelog",
    "nextname": "Express Change Log",
    "prevname": "Express Library"
  },
  {
    "page": 61,
    "previous": "https://developer.arifpay.net/#/expresscheckoutsession",
    "next": "https://developer.arifpay.net/#/wix",
    "nextname": "Wix Checkout Session",
    "prevname": "Express Checkout Session"
  },
  {
    "page": 111,
    "previous": "https://developer.arifpay.net/#/expresschangelog",
    "next": "https://developer.arifpay.net/#/wixstatusupdate",
    "nextname": "Wix Status Update",
    "prevname": "Express Change log"
  },
  {
    "page": 114,
    "previous": "https://developer.arifpay.net/#/wix",
    "next": "https://developer.arifpay.net/#/wixhttpexpose",
    "nextname": "Wix HTTP expose",
    "prevname": "Wix Checkout Session"
  },
  {
    "page": 115,
    "previous": "https://developer.arifpay.net/#/wixstatusupdate",
    "next": "https://developer.arifpay.net/#/telebirr",
    "nextname": "Telebirr",
    "prevname": "Wix Status Update"
  },
  {
    "page": 71,
    "previous": "https://developer.arifpay.net/#/wixhttpexpose",
    "next": "https://developer.arifpay.net/#/awash",
    "nextname": "Awash",
    "prevname": "Wix HTTP Expose"
  },
  {
    "page": 77,
    "previous": "https://developer.arifpay.net/#/telebirr",
    "next": "https://developer.arifpay.net/#/amole",
    "nextname": "Amole",
    "prevname": "Telebirr"
  },
  {
    "page": 78,
    "previous": "https://developer.arifpay.net/#/awash",
    "next": "https://developer.arifpay.net/#/cbe",
    "nextname": "CBE",
    "prevname": "Awash"
  },
  {
    "page": 79,
    "previous": "https://developer.arifpay.net/#/amole",
    "next": "https://developer.arifpay.net/#/hellocash",
    "nextname": "Hellocash",
    "prevname": "Amole"
  },
  {
    "page": 80,
    "previous": "https://developer.arifpay.net/#/cbe",
    "next": "https://developer.arifpay.net/#/mpesa",
    "nextname": "Mpesa",
    "prevname": "CBE"
  },
  {
    "page": 106,
    "previous": "https://developer.arifpay.net/#/cbe/hellocash",
    "next": "https://developer.arifpay.net/#/b2cpaymentsoverview",
    "nextname": "B2C Telebirr",
    "prevname": "Hellocash"
  },
  {
    "page": 86,
    "previous": "https://developer.arifpay.net/#/mpesa",
    "next": "https://developer.arifpay.net/#/b2ccbe",
    "nextname": "B2C CBE",
    "prevname": "Mpesa"
  },
  {
    "page": 110,
    "previous": "https://developer.arifpay.net/#/b2cpaymentsoverview",
    "next": "https://developer.arifpay.net/#/bytransactionid",
    "nextname": "Search By Transaction ID",
    "prevname": "B2C Telebirr"
  },
  {
    "page": 103,
    "previous": "https://developer.arifpay.net/#/b2ccbe",
    "next": "https://developer.arifpay.net/#/bysessionid",
    "nextname": "Search By Session ID",
    "prevname": "B2C CBE"
  },
  {
    "page": 108,
    "previous": "https://developer.arifpay.net/#/bytransactionid",
    "prevname": "Search By Transaction ID"
  }
  ];

export default navigationData;
  