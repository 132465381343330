import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./SideBar.css";

import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";

const removedPlugins = ["Java", "Nest", "Dot Net", "Woo commerce", "Kotlin"];

export default function Collapsed({ Routes_list }) {
  const [itemStates, setItemStates] = useState(0);
  const [activeParentIndex, setActiveParentIndex] = useState(null); // Tracks the active parent
  const [activeChildIndex, setActiveChildIndex] = useState(null); // Tracks the active child
  const [items, setItems] = useState([]);
  const [allData, setAllData] = useState([]);
  const [filteredDataArray, setFilteredDataArray] = useState([]);
  const [subFilteredDataArray, setSubFilteredDataArray] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const filter = (list) => list.filter((val) => val.Parent_id === "0");

    const fetchData = async () => {
      try {
        const response = await fetch("https://cms.arifpay.org/content-pages");
        const data = await response.json();
        setAllData(data.filter((datas) => !removedPlugins.includes(datas.Name)));
        setItems(filter(data));
        setFilteredDataArray(Array(data.length).fill([]));
        setSubFilteredDataArray(Array(data.length).fill([]));
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    fetchData();
  }, []);

  const handleItemClick = (index, id, name) => {
    setActiveParentIndex(index); // Set the active parent
    setActiveChildIndex(0); // Reset and set the first child as active by default
     setActiveChildIndex(null);
    setSubFilteredDataArray(Array(items.length).fill([])); // Reset sub-child arrays

    const matchingRoute = Routes_list.find((route) => route.Name === name);
    if (!matchingRoute) {
      console.warn(`No matching route found for name: ${name}`);
      return;
    }

    navigate(matchingRoute.Url);

    const idStr = id.toString();
    const filteredData = allData.filter((item) => item.Parent_id === idStr);
    setFilteredDataArray((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = filteredData;
      return newArray;
    });

    if (filteredData.length > 0) {
      setItemStates(index);
    }
  };

  const HandleSubItemClick = (index, id, name, e) => {
    e.stopPropagation();
    setActiveChildIndex(index); // Set the clicked child as active

    const matchingRoute = Routes_list.find((route) => route.Name === name);
    if (!matchingRoute) {
      console.warn(`No matching route found for name: ${name}`);
      return;
    }

    navigate(matchingRoute.Url);

    const idStr = id.toString();
    const filteredData = allData.filter((item) => item.Parent_id === idStr);
    setSubFilteredDataArray((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = filteredData;
      return newArray;
    });
  };

  const Handel_inner_most_child = (name, e) => {
    e.stopPropagation();
    const matchingRoute = Routes_list.find((route) => route.Name === name);
    if (!matchingRoute) {
      console.warn(`No matching route found for name: ${name}`);
      return;
    }

    navigate(matchingRoute.Url);
  };

  return (
    <>
      <link
        href="https://use.fontawesome.com/releases/v5.15.1/css/all.css"
        rel="stylesheet"
      />
      <div className="sidebar-wrapper p-1">
        <MDBAccordion
          initialActive={itemStates}
          style={{ marginTop: "0px", marginBottom: "0px" }}
          className="mdbAccordionsidebar"
        >
          {items?.map((label, index) => {
            return (
              <MDBAccordionItem
                key={index}
                collapseId={label?.id}
                headerTitle={label?.Name}
                onClick={() => handleItemClick(index, label?.id, label?.Name)}
                className="mdbItem"
              >
                <MDBAccordion initialActive={itemStates} className="mdbiconchild">
                  {filteredDataArray[index]?.map((item, childIndex) => {
                    return (
                      <MDBAccordionItem
                        collapseId={item?.id}
                        key={childIndex}
                        headerTitle={item?.Name}
                        onClick={(event) => {
                          HandleSubItemClick(childIndex, item?.id, item?.Name, event);
                        }}
                        className={`mdbItem ${
                          activeChildIndex === childIndex ? "active-child" : ""
                        }`}
                      >
                        <MDBAccordion initialActive={itemStates} className="mdbicon">
                          {subFilteredDataArray[childIndex]?.map((element) => {
                            return (
                              <MDBAccordionItem
                                key={element?.id}
                                collapseId={element?.id}
                                headerTitle={element?.Name}
                                className="mdbItem no-children"
                                onClick={(e) =>
                                  Handel_inner_most_child(element?.Name, e)
                                }
                              ></MDBAccordionItem>
                            );
                          })}
                        </MDBAccordion>
                      </MDBAccordionItem>
                    );
                  })}
                </MDBAccordion>
              </MDBAccordionItem>
            );
          })}
        </MDBAccordion>
      </div>
    </>
  );
}
