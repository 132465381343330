// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.linkColor a {
  color: #25c2a0;
  font-weight: bold;
  
  
}
.linkColor {
    margin-top: 0;
    margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/General_Content_components/css_general_content/link_component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;;;AAGnB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".linkColor a {\n  color: #25c2a0;\n  font-weight: bold;\n  \n  \n}\n.linkColor {\n    margin-top: 0;\n    margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
