// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App
{
    display: flex;
    flex-direction: column;
}

`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".App\n{\n    display: flex;\n    flex-direction: column;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
