// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main{
    min-height: 900px;
    
    padding-top: 100px;
    padding-bottom: 100px;
}
.video-container {
    height: 80vh;  
  }
   

  
  
  .react-player {
    height: 100%;
  } `, "",{"version":3,"sources":["webpack://./src/components/howto/howto.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;;IAEjB,kBAAkB;IAClB,qBAAqB;AACzB;AACA;IACI,YAAY;EACd;;;;;EAKA;IACE,YAAY;EACd","sourcesContent":[".main{\n    min-height: 900px;\n    \n    padding-top: 100px;\n    padding-bottom: 100px;\n}\n.video-container {\n    height: 80vh;  \n  }\n   \n\n  \n  \n  .react-player {\n    height: 100%;\n  } "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
