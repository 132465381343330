import { useEffect,useState } from "react";
import { useParams } from "react-router-dom";
import './css_general_content/image_content.css'
const baseurl = "https://cms.arifpay.org";
const Image=({data,id})=>{
    const param=useParams();
    const [specificImage,setSpecificImage]=useState([]);
    useEffect(()=>{
        setSpecificImage(data.filter(img=>img.id==id));
    },[data]) 
    return(
        <>{specificImage[0] && 
        <img className="content_images" src={baseurl+specificImage[0].Image.url} alt="#"/>
        }
        </>
    )
}
export default Image;