import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import navigationData from "./next_and_prev_pages";
import "./next_and_prev.css";
 

const NextandPreviousCard = ({ currPage }) => {
  const navigate = useNavigate();

  const [next, setNext] = useState(null);
  const [prev, setPrev] = useState(null);
  const [nextname, setNextname] = useState(null);
  const [prevname, setPrevname] = useState(null);

  useEffect(() => {
    const currentPageData = navigationData.find((page) => page.page === Number(currPage));
    
    if (currentPageData) {
      setNext(currentPageData.next); 
      setPrev(currentPageData.previous);
      setNextname(currentPageData.nextname);
      setPrevname(currentPageData.prevname);
    }
  }, [currPage]);

  const handleClick = (url) => {
    try {
      if (!url || typeof url !== 'string') {
        throw new Error('Invalid URL provided');
      }

      const splitUrl = url.split('/#/');
      if (splitUrl.length !== 2) {
        throw new Error('URL format is invalid');
      }

      const path = splitUrl[1];
      if (!path) {
        throw new Error('Path segment is empty');
      }

      navigate(`/${path}`);
    } catch (error) {
      console.error('Navigation error:', error.message);
      
    }
  };

  return (
    <div
    className="btn-container"
    style={{
      display: "flex",
      justifyContent: prev && next ? "space-between" : "flex-start",
      marginTop: "50px",
      padding: "0 20px",
    }}
  >
    {prev && (
      <button
      className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-[#007bff] rounded shadow"
      onClick={() => handleClick(prev)}
        style={{
          maxHeight: "60px",
          lineHeight: "1.2",
          overflow: "hidden",
          // borderRight: "solid 3px lime",
          color: "blue",
        }}
        // style={{
        //   // padding: "10px 20px",
        //   fontSize: "16px",
        //   borderRadius: "5px",
        //   border: "solid",
        //   borderColor:"#007bff",
        //   // backgroundColor: "#007bff",
        //   color: "Black",
        //   cursor: "pointer",
        //   transition: "background-color 0.3s",
        // }}
      >
        ← Previous -
        <br />
        <span style={{ fontSize: "14px", opacity: "0.8" }}>{prevname}</span>
      </button>
    )}
  
    {next && (
      <button
        onClick={() => handleClick(next)}
         className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-[#007bff] rounded shadow"
        style={{
          maxHeight: "60px",
          lineHeight: "1.2",
          overflow: "hidden",
          // borderRight: "solid 3px lime",
          color: "green",
        }}
      >
       - Next →
        <br />
        <span style={{ fontSize: "14px", opacity: "0.8" }}>{nextname}</span>
      </button>
    )}
  </div>
  

  );
};

export default NextandPreviousCard;
