// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  padding-right: 100px;
  gap: 20px; 
}

.cardLink {
  color: black;
}

.button1,
.button2 {
  padding: 20px 30px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #ffffff;
  color: #25c2a0;
  border: 1px solid #ddd;
  outline: none;
  transition: background-color 0.3s ease;
  text-align: left; 
  flex-shrink: 0; 
  display: inline-block; 
}

.button1:hover,
.button2:hover {
  background-color: #25c2a0;
  color: #ffffff;
}

.cardLink:hover {
  color: #ffffff;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

@media (max-width: 768px) {
  .btn-container {
    flex-direction: column;
    align-items: center;
    padding-right: 0; 
  }

  .button1,
  .button2 {
    text-align: center;
    width: auto; 
    margin-bottom: 10px; 
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/card/next_and_prev/next_and_prev.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,cAAc;EACd,oBAAoB;EACpB,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;;EAEE,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,yBAAyB;EACzB,cAAc;EACd,sBAAsB;EACtB,aAAa;EACb,sCAAsC;EACtC,gBAAgB;EAChB,cAAc;EACd,qBAAqB;AACvB;;AAEA;;EAEE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE;IACE,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;;IAEE,kBAAkB;IAClB,WAAW;IACX,mBAAmB;EACrB;AACF","sourcesContent":[".btn-container {\n  display: flex;\n  justify-content: space-between;\n  margin: 20px 0;\n  padding-right: 100px;\n  gap: 20px; \n}\n\n.cardLink {\n  color: black;\n}\n\n.button1,\n.button2 {\n  padding: 20px 30px;\n  border-radius: 10px;\n  cursor: pointer;\n  background-color: #ffffff;\n  color: #25c2a0;\n  border: 1px solid #ddd;\n  outline: none;\n  transition: background-color 0.3s ease;\n  text-align: left; \n  flex-shrink: 0; \n  display: inline-block; \n}\n\n.button1:hover,\n.button2:hover {\n  background-color: #25c2a0;\n  color: #ffffff;\n}\n\n.cardLink:hover {\n  color: #ffffff;\n}\n\n.disabled {\n  cursor: not-allowed;\n  opacity: 0.5;\n}\n\n@media (max-width: 768px) {\n  .btn-container {\n    flex-direction: column;\n    align-items: center;\n    padding-right: 0; \n  }\n\n  .button1,\n  .button2 {\n    text-align: center;\n    width: auto; \n    margin-bottom: 10px; \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
