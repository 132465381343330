import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";

import Button from "./buttoncomponent";
import ContentCard from "./ContentCard";
import "../Homepage/homepage.css";

const HomePage = () => {
  const navigate = useNavigate();
  const [contentOneResponse, setContentOneResponse] = useState(null);
  const [contentTwoResponse, setContentTwoResponse] = useState(null);

  const fetchData = async (url, setData) => {
    try {
      const response = await axios.get(url);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(
      "https://cms.arifpay.org/home-page-content-twos",
      setContentOneResponse
    );
    fetchData(
      "https://cms.arifpay.org/home-page-content-ones",
      setContentTwoResponse
    );
  }, []);

  const handleClick = () => {
    navigate("/overview");
  };

  return (
    <Container fluid className="Homepage_content_container">
      <Row className="Homepage_content1">
        {contentTwoResponse && (
          <Col xs={12}>
            <h1 style={{ margin: "15px" }}>{contentTwoResponse[0].Title}</h1>
            <h3 style={{ margin: "15px" }}>
              {contentTwoResponse[0].Descriptions}
            </h3>
            <Button
              onClick={handleClick}
              style={{ margin: "15px" }}
              button_content="Get-Started With Arifpay- 5 min ⏱️"
            />
          </Col>
        )}
      </Row>
      {contentOneResponse && (
        <Container fluid className="Homepage_content2">
          <Row className="justify-content-center">
            {contentOneResponse
              .slice()
              .reverse()
              .map((content, index) => (
                <Col key={index} xs={12} md={6} lg={4} className="p-2 mb-3">
                  <ContentCard content={content} index={index} />
                </Col>
              ))}
          </Row>
        </Container>
      )}
    </Container>
  );
};

export default HomePage;

// import Button from "../Button/button";
// import "../Homepage/homepage.css";

// import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   MDBCard,
//   MDBCardBody,
//   MDBCardTitle,
//   MDBCardText,
//   MDBCardImage,
// } from "mdb-react-ui-kit";

// import ReactPlayer from "react-player";
// import { MDBContainer } from "mdb-react-ui-kit";
// import axios from "axios";
// import { Container, Row, Col } from "react-bootstrap";

// const HomePage = () => {
//   const baseurl = "https://cms.arifpay.org";

//   const navigate = useNavigate();
//   const [contentone_response, setcontentOneresponse] = useState(null);
//   const [contentTwo_response, setcontentTworesponse] = useState(null);
//   useEffect(() => {
//     const contentone_fetch_api = async () => {
//       await axios
//         .get("https://cms.arifpay.org/home-page-content-twos")
//         .then((res) => {
//           setcontentOneresponse(res?.data);
//         })
//         .catch((error) => {
//           console.error("Error fetching data: ", error);
//         });
//     };
//     const contentTwo_fetch_api = async () => {
//       await axios
//         .get("https://cms.arifpay.org/home-page-content-ones")
//         .then((res) => {
//           setcontentTworesponse(res?.data);
//         })
//         .catch((error) => {
//           console.error("Error fetching data: ", error);
//         });
//     };
//     contentone_fetch_api();
//     contentTwo_fetch_api();
//   }, []);
//   const handel_click = () => {
//     navigate("/overview");
//   };
//   return (
//     <Container fluid className="Homepage_content_container">
//       <Row className="Homepage_content1">
//         {contentTwo_response && (
//           <Col xs={12}>
//             <h1 style={{ margin: "15px" }}>{contentTwo_response[0].Title}</h1>
//             <h3 style={{ margin: "15px" }}>
//               {contentTwo_response[0].Descriptions}
//             </h3>
//             <Button
//               onClick={() => handel_click()}
//               style={{ margin: "15px" }}
//               button_content="Get-Started With Arifpay- 5 min ⏱️"
//             />
//           </Col>
//         )}
//       </Row>

//       {contentone_response && (
//         <Container fluid className="Homepage_content2">
//           <Row className="justify-content-center">
//             <Col xs={12} md={6} lg={4} className="p-2 mb-3">
//               <MDBCard style={{ border: "none", boxShadow: "none" }}>
//                 <MDBContainer>
//                   <div
//                     style={{
//                       // scrolling: "no",
//                       width: "100%",
//                       height: "500px",
//                       overflow: "hidden",
//                     }}
//                   >
//                     <ReactPlayer
//                       playing={true}
//                       loop={true}
//                       muted={true}
//                       width="100%"
//                       height="100%"
//                       url={["https://youtu.be/dHKFyVbkBck"]}
//                     />
//                   </div>
//                 </MDBContainer>
//                 <MDBCardBody>
//                   <MDBCardTitle className="h3 text-center">
//                     {contentone_response[3].Title}
//                   </MDBCardTitle>
//                   <MDBCardText>
//                     {contentone_response[3].Descriptions}
//                   </MDBCardText>
//                 </MDBCardBody>
//               </MDBCard>
//             </Col>

//             <Col xs={12} md={6} lg={4} className="p-2 mb-3">
//               <MDBCard style={{ border: "none", boxShadow: "none" }}>
//                 <MDBContainer>
//                   <div
//                     style={{
//                       scrolling: "no",
//                       width: "100%",
//                       height: "500px",
//                       overflow: "hidden",
//                     }}
//                   >
//                     <ReactPlayer
//                       playing={true}
//                       loop={true}
//                       muted={true}
//                       width="100%"
//                       height="100%"
//                       url={[
//                         "https://www.youtube.com/embed/OE6faOPOiOg?autoplay=1&mute=1&loop=1&controls=0",
//                         "https://www.youtube.com/embed/B4BZJbeEqy4?autoplay=1&mute=1&loop=1&controls=0",
//                       ]}
//                     />
//                   </div>
//                 </MDBContainer>
//                 <MDBCardBody>
//                   <MDBCardTitle className="h3 text-center">
//                     {contentone_response[0].Title}
//                   </MDBCardTitle>
//                   <MDBCardText>
//                     {contentone_response[0].Descriptions}
//                   </MDBCardText>
//                 </MDBCardBody>
//               </MDBCard>
//             </Col>

//             <Col xs={12} md={6} lg={4} className="p-2 mb-3">
//               <MDBCard style={{ border: "none", boxShadow: "none" }}>
//                 <MDBContainer>
//                   <div
//                     style={{
//                       scrolling: "no",
//                       width: "100%",
//                       height: "500px",
//                       overflow: "hidden",
//                     }}
//                   >
//                     <ReactPlayer
//                       playing={true}
//                       loop={true}
//                       muted={true}
//                       width="100%"
//                       height="100%"
//                       url={[
//                         "https://www.youtube.com/embed/B4BZJbeEqy4?autoplay=1&mute=1&loop=1&controls=0",
//                         "https://youtu.be/I-rctuy6gq0",
//                       ]}
//                     />
//                   </div>
//                 </MDBContainer>
//                 <MDBCardBody>
//                   <MDBCardTitle className="h3 text-center">
//                     {contentone_response[1].Title}
//                   </MDBCardTitle>
//                   <MDBCardText>
//                     {contentone_response[1].Descriptions}
//                   </MDBCardText>
//                 </MDBCardBody>
//               </MDBCard>
//             </Col>

//             <Col xs={12} md={6} lg={4} className="p-2 mb-3">
//               <MDBCard style={{ border: "none", boxShadow: "none" }}>
//                 <MDBContainer>
//                   <div
//                     style={{
//                       scrolling: "no",
//                       width: "100%",
//                       height: "500px",
//                       overflow: "hidden",
//                     }}
//                   >
//                     <ReactPlayer
//                       playing={true}
//                       loop={true}
//                       muted={true}
//                       width="100%"
//                       height="100%"
//                       url={[
//                         "https://www.youtube.com/embed/_KgSXRNzsfk?autoplay=1&mute=1&loop=1&controls=0",
//                         "https://youtu.be/becdnzEM-5U",
//                       ]}
//                     />
//                   </div>
//                 </MDBContainer>
//                 <MDBCardBody>
//                   <MDBCardTitle className="h3 text-center">
//                     {contentone_response[2].Title}
//                   </MDBCardTitle>
//                   <MDBCardText>
//                     {contentone_response[2].Descriptions}
//                   </MDBCardText>
//                 </MDBCardBody>
//               </MDBCard>
//             </Col>
//           </Row>
//         </Container>
//       )}
//     </Container>
//   );
// };
// export default HomePage;
