import { useEffect, useState } from "react";
import "./css_general_content/note_content.css";
import { useParams } from "react-router-dom";
const Note = ({ id, data }) => {
  const param=useParams();
  const [specificNote, setSpecificNote] = useState(null);
  useEffect(() => {
    setSpecificNote(data.filter((note) => note.id == id));
  }, [data]);
  return (
    <div className="first">
      {specificNote && (
        <div className="second">
          <div className="third">
            {specificNote.map((note, idx) => (
              <div key={idx}>
                <div className="forth">
                  ✓ <span>{note.Title}</span>
                </div>
                <p className="note" key={idx}>
                  {note.Description}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Note;
