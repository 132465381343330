// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link {
    color: black;
}
.navItems {
    color: #25c2a0
}
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 23px;
    border-radius: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #0c0d0d;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  /* Rounded sliders */
  .slider.round {
    border-radius: 40px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .navItems.active {
    font-weight: bold; /* Example style for active state */
    color: blue; /* Example color for active state */
  }`, "",{"version":3,"sources":["webpack://./src/components/Nav-bar/nav-bar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI;AACJ;AACA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,QAAQ;IACR,SAAS;EACX;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,sBAAsB;IAEtB,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,SAAS;IACT,WAAW;IACX,uBAAuB;IAEvB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,2BAA2B;EAC7B;EACA;IAGE,2BAA2B;EAC7B;EACA,oBAAoB;EACpB;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;EACA;IACE,iBAAiB,EAAE,mCAAmC;IACtD,WAAW,EAAE,mCAAmC;EAClD","sourcesContent":[".link {\n    color: black;\n}\n.navItems {\n    color: #25c2a0\n}\n.switch {\n    position: relative;\n    display: inline-block;\n    width: 50px;\n    height: 23px;\n    border-radius: 34px;\n  }\n  \n  .switch input {\n    opacity: 0;\n    width: 0;\n    height: 0;\n  }\n  \n  .slider {\n    position: absolute;\n    cursor: pointer;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: #ccc;\n    -webkit-transition: .4s;\n    transition: .4s;\n  }\n  \n  .slider:before {\n    position: absolute;\n    content: \"\";\n    height: 16px;\n    width: 16px;\n    left: 4px;\n    bottom: 4px;\n    background-color: white;\n    -webkit-transition: .4s;\n    transition: .4s;\n  }\n  \n  input:checked + .slider {\n    background-color: #0c0d0d;\n  }\n  \n  input:focus + .slider {\n    box-shadow: 0 0 1px #2196F3;\n  }\n  input:checked + .slider:before {\n    -webkit-transform: translateX(26px);\n    -ms-transform: translateX(26px);\n    transform: translateX(26px);\n  }\n  /* Rounded sliders */\n  .slider.round {\n    border-radius: 40px;\n  }\n  \n  .slider.round:before {\n    border-radius: 50%;\n  }\n  .navItems.active {\n    font-weight: bold; /* Example style for active state */\n    color: blue; /* Example color for active state */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
